import Markdown from 'marked-react';
import { Homepage } from '@/typings/types';

type SkillsRendererProps = {
  skills: Homepage['skill'];
};

const SkillsRenderer = ({ skills }: SkillsRendererProps) => {
  return (
    <div
      id="skills"
      className={
        'relative z-40 w-full bg-chocolate text-white lg:w-8/12 xl:ml-40 xl:w-[30%] 3xl:w-[27%]'
      }
    >
      <div className={'pt-16 pb-24'}>
        <h2
          className={
            'px-5 pb-7 font-stigsaMedium text-5xl font-medium uppercase lg:pl-[8.5rem] lg:pr-[9rem] 3xl:pl-[11rem] 3xl:pr-[12rem]'
          }
        >
          {skills.title}
        </h2>
        <div
          className={
            'prose min-w-fit px-4 prose-p:font-libertineRegular prose-p:text-lg prose-p:text-white first:prose-p:uppercase prose-strong:text-white lg:pl-[8.5rem] lg:pr-[7rem] 3xl:pl-[11rem] 3xl:pr-[12rem]'
          }
        >
          {skills?.content && <Markdown>{skills?.content}</Markdown>}
        </div>
      </div>
    </div>
  );
};

export default SkillsRenderer;
