import Background from '../header/background';
import SkillsRenderer from './skillsRenderer';
import { Homepage } from '@/typings/types';

type SkillWrapperProps = {
  skill: Homepage['skill'];
};

const SkillWrapper = ({ skill }: SkillWrapperProps) => {
  return (
    <section className={'relative w-full py-24'}>
      <Background priority={false} background={skill.background} />
      <SkillsRenderer skills={skill} />
    </section>
  );
};

export default SkillWrapper;
